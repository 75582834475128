<template>
   <div class="my-16">
      <div class="mx-auto animate__animated animate__fadeIn md:max-w-md">
         <div class="mx-5 md:mx-auto pt-6 pb-10 px-10 md:w-full bg-white rounded-md shadow-md">
            <h3 class="text-3xl font-semibold mt-2 text-indigo-900 mb-8 sm:mb-10">
               Admin Login
            </h3>
            <ValidationObserver v-slot="{ handleSubmit }">
               <form class="flex flex-col justify-center mt-5" @submit.prevent="handleSubmit(login)">
                  <div class="flex flex-col space-y-4">
                     <ValidationProvider tag="div" name="Email" rules="required|email" v-slot="{ errors }">
                        <label for="email" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Email</label>
                        <input type="email" v-model="form.email"
                           class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                           autocomplete="off" spellcheck="false"/>
                           <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="Password" rules="required|min:8" v-slot="{ errors }">
                        <label for="password" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Password</label>
                        <input type="password" v-model="form.password" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                           autocomplete="new-password" autocapitalize="off" spellcheck="false"/>
                        <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <div class="flex items-center justify-center pt-5">
                        <div class="flex items-center flex-shrink-0">
                           <input id="remember" type="checkbox" class="rounded mr-2" v-model="form.remember" />
                           <label
                              for="remember"
                              class="font-semibold text-center text-sm text-gray-500 flex-shrink-0 pt-1 text-sm text-heading cursor-pointer"
                              >Remember me</label
                           >
                        </div>
                        <div class="flex ml-auto">
                           <router-link to="/auth/password/reset" class="font-semibold text-center text-sm text-gray-500 pt-1 text-end text-sm text-heading ps-3 underline hover:no-underline focus:no-underline focus:outline-none">
                              Forgot password?
                           </router-link>
                        </div>
                     </div>
                     <div class="relative">
                        <button class="inline-flex items-center cursor-pointer transition 
                           ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md 
                           focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                           hover:bg-indigo-800 w-full mt-1.5" type="submit">
                           <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                           </svg> Login</button>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
         </div>
      </div>
   </div>
</template>


<script>
//import { mapActions } from 'vuex'
export default {

   data() {
      return {
         form: {
            platform: "web"
         },
         isLoading: false,
      }
   },

   methods: {
     // ...mapActions('auth', ['login']),

      async login() {
         this.isLoading = true
         try {
            let res = await this.$store.dispatch('auth/admin_login', this.form)
            this.$toast.success(res.message)
            this.isLoading = false
            this.$router.push( { name: 'AdminDashboard', params: { id: this.$route.params.id, token: this.$route.params.token } } )
         } catch (err) {
            this.isLoading = false
            if(err.response && err.response.status == 400 || err.response.status == 422 || err.response.status == 403) {
					this.$toast.error(err.response.data.message)
				}
         }
      }
   }
}
</script>